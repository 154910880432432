<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        {{ $t('common.filters') }}
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2"
        >
          <label>{{ $t('common.company') }}</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="companyFilter"
            :options="companyOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:companyFilter', val)"
          />
        </b-col>
        <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2"
        >
          <label>{{ $t('common.location') }}</label>
          <v-select
            :value="objectFilter"
            :multiple="true"
            ooptions="companyFilter? objectOptions[companyFilter]: objectOptions['all']"
            :options="objectOptions.filter(item => !companyFilter || item.company === companyFilter)"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:objectFilter', val)"
          />
        </b-col>
        <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2"
        >
          <label>{{ $t('common.machine') }}</label>
          <v-select
            ref="selSalesMachine"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="machineFilter"
            :options="machineOptions.filter(item => (!companyFilter || item.company === companyFilter) && (!objectFilter.length || objectFilter.includes(item.object)))"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:machineFilter', val)"
          />
        </b-col>

        <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2 d-flex align-items-center"
        >
          <!--div class="font-small-2 mr-25 mb-0 float-right">
            <span class="text-primary pr-1 cursor-pointer">Today</span>
            <span class="text-secondary pr-1 cursor-pointer">Yesterday</span>
            <span class="text-secondary pr-1 cursor-pointer">Week</span>
            <span class="text-secondary pr-1 cursor-pointer">Month</span>

            <div class="d-flex align-items-center">
              <feather-icon
                icon="CalendarIcon"
                size="16"
              />
              <flat-pickr
                v-model="range"
                :config="{ mode: 'range', enableTime: false, onChange: changeDate }"
                class="form-control flat-picker bg-transparent border-0 shadow-none"
                placeholder="YYYY-MM-DD"
              />
            </div>
          </div-->
          <ExtDatePicker
            :value.sync="range"
            class="mt-1"
          />
        </b-col>

        <!--b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Status</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="statusFilter"
            :options="statusOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:statusFilter', val)"
          />
        </b-col-->
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
// import { ref } from '@vue/composition-api'
// import sharedSalesList from './sharedSalesList'
import ExtDatePicker from '@core/components/ext-date-picker/ExtDatePicker.vue'

export default {
  components: {
    ExtDatePicker,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
    // flatPickr,
    // ExtFlatPickr,
  },
  props: {

    companyFilter: {
      type: [Number, null],
      default: null,
    },
    objectFilter: {
      type: Array,
      default: () => [],
    },
    machineFilter: {
      type: [Number, null],
      default: null,
    },
    statusFilter: {
      type: [String, null],
      default: null,
    },
    companyOptions: {
      type: Array,
      required: true,
    },
    machineOptions: {
      type: Array,
      required: true,
    },
    objectOptions: {
      type: Array,
      required: true,
    },
    /*
    statusOptions: {
      type: Array,
      required: true,
    },
    */
    rangePicker: {
      type: Array,
      required: true,
    },
  },
  computed: {
    range: {
      get() {
        return this.rangePicker
      },
      set(newValue) {
        this.$emit('update:rangePicker', newValue)
      },
    },
  },
  watch: {
    companyFilter(val) {
      this.$store.commit('appConfig/UPDATE_COMPANY_FILTER', val)
      if (!val) return
      if (this.objectFilter) {
        const item = this.objectOptions.find(obj => obj.value === this.objectFilter)
        if (item.company !== val) this.$refs.selSalesLocation.clearSelection()
      }
      if (this.machineFilter) {
        const item = this.machineOptions.find(obj => obj.value === this.machineFilter)
        if (item.company !== val) this.$refs.selSalesMachine.clearSelection()
      }
    },
    objectFilter(val) {
      this.$store.commit('appConfig/UPDATE_OBJECT_FILTER', val)
      if (!val) return
      if (this.machineFilter) {
        const item = this.machineOptions.find(obj => obj.value === this.machineFilter)
        if (item.object !== val) this.$refs.selSalesMachine.clearSelection()
      }
    },
    machineFilter(val) {
      this.$store.commit('appConfig/UPDATE_MACHINE_FILTER', val)
    },
  },
  /*
  setup(props, { emit }) {
    // console.log('START', props.rangePicker)

    // const range = ref([...props.rangePicker])

    /*
    const changeDate = () => {
      let newvalue = Array.isArray(range.value) ? range.value.join(' ') : range.value.trim().split(' ')
      newvalue = Array.isArray(newvalue) ? [newvalue[0], newvalue[2]].join(' ') : newvalue

      const oldvalue = props.rangePicker.join(' ')

      console.log('NEW 1', oldvalue, newvalue)
      if (oldvalue !== newvalue) {
        console.log('EMITTED', props.rangePicker, range.value, newvalue.split(' '))
        // emit('update:rangePicker_', range.value.split(' '))
        emit('update:rangePicker', newvalue.split(' '))
      }
      console.log('NEW 2', props.rangePicker)
    }
    */

  /*
    watch([rangePicker], () => {
      console.log('CLOSED !!', rangePicker.value)
      root.$emit('update:machineFilter', 9999)
      console.log('CLOSED ??', rangePicker.value)
    })
    */

  /* * /
    let companyOptions = ref([])
    store.dispatch('app-sale/fetchCompanies')
            .then(response => {
              console.log('RESP', response.data)
              companyOptions = response.data.data
              console.log('companyOptions', companyOptions)
            })
            .catch(() => {
              toast({
                component: ToastificationContent,
                props: {
                  title: 'Error fetching companies list',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
    * /
    return {
      // changeDate,
      // range,
    }
  },
  */
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
