<template>

  <div>

    <!--sale-list-add-new
      :is-add-new-sale-sidebar-active.sync="isAddNewSaleSidebarActive"
      :machine-options="machineOptions"
      :company-options="companyOptions"
      @refetch-data="refetchData"
    /-->

    <!-- Filters -->
    <sales-list-filters
      :company-filter.sync="companyFilter"
      :company-options="companyOptions"

      :object-filter.sync="objectFilter"
      :object-options="objectOptions"

      :machine-filter.sync="machineFilter"
      :machine-options="machineOptions"

      :range-picker.sync="rangePicker"
    />
    <b-tabs
      pills
    >

      <b-tab active>
        <template #title>
          <feather-icon
            icon="CpuIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('drinks.beverages') }}</span>
        </template>

        <!-- Table Container Card -->
        <b-card
          no-body
          class="mb-0"
        >

          <div class="m-2">

            <!-- Table Top -->
            <b-row>

              <!-- Per Page -->
              <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>{{ $t('common.show') }}</label>
                <v-select
                  v-model="perPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>{{ $t('common.entries') }}</label>
              </b-col>

              <!-- Search -->
              <b-col
                cols="12"
                md="6"
              >
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input
                    v-model="searchQuery"
                    class="d-inline-block mr-1"
                    :placeholder="$t('common.search')"
                  />
                  <!--b-button
                variant="primary"
                @click="isAddNewSaleSidebarActive = true"
              >
                <span class="text-nowrap">Add Sale</span>
              </b-button-->

                  <b-dropdown
                    variant="link"
                    no-caret
                    :right="$store.state.appConfig.isRTL"
                  >

                    <template #button-content>
                      <feather-icon
                        icon="DownloadIcon"
                        size="16"
                        class="align-middle text-body"
                      />
                    </template>

                    <b-dropdown-item @click="fetchSalesFile()">
                      <feather-icon icon="AlignJustifyIcon" />
                      <span class="align-middle ml-50">{{ $t('common.save_xlsx') }}</span>
                    </b-dropdown-item>

                    <b-dropdown-item
                      :disabled="true"
                      @click="fetchSalesFile('pdf')"
                    >
                      <feather-icon icon="BookIcon" />
                      <span class="align-middle ml-50">{{ $t('common.save_pdf') }}</span>
                    </b-dropdown-item>
                  </b-dropdown>

                  <b-button
                    variant="flat-info"
                    class="btn-icon rounded-circle"
                    @click="refetchData"
                  >
                    <feather-icon
                      icon="RefreshCwIcon"
                      size="18"
                    />
                  </b-button>
                </div>
              </b-col>
            </b-row>

          </div>

          <b-table
            ref="refSaleListTable"
            v-model="visibleRows"
            class="position-relative"
            :items="fetchSales"
            responsive
            :fields="tableColumns"
            primary-key="id"
            :sort-by.sync="sortBy"
            show-empty
            empty-text="No matching records found"
            :sort-desc.sync="isSortDirDesc"
          >

            <!-- Column: Sale -->
            <template #cell(name)="data">
              <b-media vertical-align="center">
                <b-link
                  :to_="{ name: 'apps-sales-view', params: { id: data.item.id } }"
                  :to="{ name: 'apps-sales-edit', params: { id: data.item.id } }"
                  class="font-weight-bold d-block text-nowrap"
                >
                  {{ data.item.name }}
                </b-link>
                <small class="text-muted">@{{ data.item.serial }}</small>
              </b-media>
            </template>

            <template #cell(price)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize">{{ parseFloat(Number(data.item.price)).toFixed(2) }}</span>
              </div>
            </template>

            <!-- Column: Object  -->
            <template #cell(machineId)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize">{{ resolveSaleMachineText(data.item.machineId) }}</span>
              </div>
            </template>

            <template #cell(details)="data">
              <div class="text-nowrap">

                <span
                  v-if="data.item.details_raw && data.item.details_raw.RcptRef"
                  class="align-text-top"
                ><a
                  target="_blank"
                  :href="data.item.details_raw.RcptRef"
                >{{ data.item.details }}</a></span>
                <span
                  v-else
                  class="align-text-top"
                >{{ data.item.details }}</span>
              </div>
            </template>

            <!-- Column: Company -->
            <template #cell(company_id)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize">{{ resolveSaleCompanyText(data.item.companyId) }}</span>
              </div>
            </template>

            <!-- Column: Status -->
            <template #cell(status)="data">
              <b-badge
                pill
                :variant="`light-${resolveSaleStatusVariant(data.item.status)}`"
                class="text-capitalize"
              >
                {{ data.item.status }}
              </b-badge>
            </template>

            <!-- Column: Actions -->
            <template #cell(actions)="data">
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >

                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <!--b-dropdown-item :to="{ name: 'apps-sales-view', params: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Details</span>
            </b-dropdown-item-->

                <b-dropdown-item :to="{ name: 'apps-sales-edit', params: { id: data.item.id } }">
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">{{ $t('common.edit') }}</span>
                </b-dropdown-item>

                <b-dropdown-item @click="deleteSale(data.item)">
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">{{ $t('common.delete') }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
            <template #cell(withCheck)="data">
              <a
                v-if="data.item.withCheck"
                :href="`${backendUrl}apps/sale/payments-bill/${data.item.id}`"
                class="font-weight-bold d-block text-nowrap btn-link"
                download
                target="_blank"
              >
                <feather-icon
                  icon="DownloadIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </a>
            </template>
            <template slot="bottom-row">
              <th>{{ $t('common.total') }}</th>
              <td />
              <td />
              <!-- this is a computed prop that adds up all the expenses in the visible rows -->
              <th>{{ Number(totalPrice).toFixed(2) }}</th>
              <th>{{ Number(totalSales).toFixed(0) }}</th>
              <td />
            </template>

          </b-table>
          <div class="mx-2 mb-2">
            <b-row>

              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted">{{ $t('common.showing') }} {{ dataMeta.from }} {{ $t('common.to') }} {{ dataMeta.to }} {{ $t('common.of') }} {{ dataMeta.of }} {{ $t('common.entries') }}</span>
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >

                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalSales"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>

              </b-col>

            </b-row>
          </div>
        </b-card>

      </b-tab>

      <b-tab>
        <template #title>
          <feather-icon
            icon="ClipboardIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('common.report') }}</span>
        </template>

        <b-card
          no-body
          class="mb-0"
        >

          <div class="m-2">

            <!-- Table Top -->
            <b-row>

              <!-- Per Page -->
              <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>{{ $t('common.show') }}</label>
                <v-select
                  v-model="perReportPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="perPageReportOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>{{ $t('common.entries') }}</label>
              </b-col>

              <!-- Search -->
              <b-col
                cols="12"
                md="6"
              >
                <div class="d-flex align-items-center justify-content-end">
                  <!--b-form-input
                    v-model="searchReportQuery"
                    class="d-inline-block mr-1"
                    :placeholder="$t('common.search')"
                  /-->
                  <!--b-button
                variant="primary"
                @click="isAddNewSaleSidebarActive = true"
              >
                <span class="text-nowrap">Add Sale</span>
              </b-button-->

                  <b-dropdown
                    variant="link"
                    no-caret
                    :right="$store.state.appConfig.isRTL"
                  >

                    <template #button-content>
                      <feather-icon
                        icon="DownloadIcon"
                        size="16"
                        class="align-middle text-body"
                      />
                    </template>

                    <b-dropdown-item @click="fetchSalesFile('xlsx', 'report')">
                      <feather-icon icon="AlignJustifyIcon" />
                      <span class="align-middle ml-50">{{ $t('common.save_xlsx') }}</span>
                    </b-dropdown-item>

                    <b-dropdown-item
                      :disabled="true"
                      @click="fetchSalesFile('pdf')"
                    >
                      <feather-icon icon="BookIcon" />
                      <span class="align-middle ml-50">{{ $t('common.save_pdf') }}</span>
                    </b-dropdown-item>
                  </b-dropdown>

                  <b-button
                    variant="flat-info"
                    class="btn-icon rounded-circle"
                    @click="refetchData"
                  >
                    <feather-icon
                      icon="RefreshCwIcon"
                      size="18"
                    />
                  </b-button>
                </div>
              </b-col>
            </b-row>

          </div>
          <b-table
            ref="refSaleListReportTable"
            v-model="visibleReportRows"
            :busy="isReportBusy"
            class="position-relative"
            :items="fetchReportSales"
            responsive
            :fields="tableReportColumns"
            primary-key="id"
            :sort-by.sync="sortBy"
            show-empty
            empty-text="No matching records found"
            :sort-desc.sync="isSortDirDesc"
          >

            <!-- Column: Sale -->
            <template #cell(name)="data">
              <b-media vertical-align="center">
                <b-link
                  :to_="{ name: 'apps-sales-view', params: { id: data.item.id } }"
                  :to="{ name: 'apps-sales-edit', params: { id: data.item.id } }"
                  class="font-weight-bold d-block text-nowrap"
                >
                  {{ data.item.name }}
                </b-link>
                <small class="text-muted">@{{ data.item.serial }}</small>
              </b-media>
            </template>

            <template #cell(price)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize">{{ parseFloat(Number(data.item.price)).toFixed(2) }}</span>
              </div>
            </template>

            <!-- Column: Object  -->
            <template #cell(machineId)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize">{{ resolveSaleMachineText(data.item.machineId) }}</span>
              </div>
            </template>

            <template #cell(details)="data">
              <div class="text-nowrap">

                <span
                  v-if="data.item.details_raw && data.item.details_raw.RcptRef"
                  class="align-text-top"
                ><a
                  target="_blank"
                  :href="data.item.details_raw.RcptRef"
                >{{ data.item.details }}</a></span>
                <span
                  v-else
                  class="align-text-top"
                >{{ data.item.details }}</span>
              </div>
            </template>

            <!-- Column: Company -->
            <template #cell(company_id)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize">{{ resolveSaleCompanyText(data.item.companyId) }}</span>
              </div>
            </template>

            <!-- Column: Status -->
            <template #cell(status)="data">
              <b-badge
                pill
                :variant="`light-${resolveSaleStatusVariant(data.item.status)}`"
                class="text-capitalize"
              >
                {{ data.item.status }}
              </b-badge>
            </template>

            <!-- Column: Actions -->
            <template #cell(actions)="data">
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >

                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <!--b-dropdown-item :to="{ name: 'apps-sales-view', params: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Details</span>
            </b-dropdown-item-->

                <b-dropdown-item :to="{ name: 'apps-sales-edit', params: { id: data.item.id } }">
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">{{ $t('common.edit') }}</span>
                </b-dropdown-item>

                <b-dropdown-item @click="deleteSale(data.item)">
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">{{ $t('common.delete') }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>

            <template #custom-foot="">
              <tr>
                <th>{{ $t('common.total') }}</th>
                <template v-for="(field, i) in tableReportColumns">
                  <th
                    v-if="i > 0"
                    :key="'total-' + i"
                  >
                    {{ Number(totalColumn(field.key)).toFixed(0) }}
                  </th>
                </template>
                <td />
              </tr>
              <tr>
                <th>{{ $t('common.totalBeverages') }}</th>
                <th :colspan="tableReportColumns.length - 1">
                  {{ Number(totalBeverages).toFixed(0) }}
                </th>
                <td />
              </tr>
            </template>

          </b-table>
          <div class="mx-2 mb-2">
            <b-row>

              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted">{{ $t('common.showing') }} {{ dataReportMeta.from }} {{ $t('common.to') }} {{ dataReportMeta.to }} {{ $t('common.of') }} {{ dataReportMeta.of }} {{ $t('common.entries') }}</span>
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >

                <b-pagination
                  v-model="currentReportPage"
                  :total-rows="totalReportSales"
                  :per-page="perReportPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>

              </b-col>

            </b-row>
          </div>
        </b-card>

      </b-tab>

      <b-tab
        @click="ApexChartsExec()"
      >
        <template #title>
          <feather-icon
            icon="CpuIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('drinks.chart') }}</span>
        </template>
        <b-card
          no-body
          class="card-revenue-budget"
        >
          <b-row class="mx-0">
            <b-col
              md="12"
              class="revenue-report-wrapper"
            >
              <div class="d-sm-flex justify-content-between align-items-center mb-3">
                <h4 class="card-title mb-50 mb-sm-0">
                  {{ $t('drinks.report') }}
                </h4>
                <div class="d-flex align-items-center">
                  <!--div class="d-flex align-items-center mr-2">
                <span class="bullet bullet-primary svg-font-small-3 mr-50 cursor-pointer" />
                <span>Sales, pcs</span>
              </div-->
                  <!--div class="d-flex align-items-center">
                <span class="bullet bullet-warning svg-font-small-3 mr-50 cursor-pointer" />
                <span>Expense</span>
              </div-->
                </div>
              </div>

              <!-- chart -->
              <vue-apex-charts
                v-if="series[0].data.length > 0"
                id="revenue-report-chart"
                ref="chart"
                type="bar"
                :height="chartOptions.chart.height"
                :options="chartOptions"
                :series="series"
              />
            </b-col>

          </b-row>
        </b-card>

      </b-tab>

    </b-tabs>

  </div>
</template>

<script>
import {
  // BCard, BRow, BCol, BFormInput,
  BButton,
  // BTable, BMedia, BAvatar, BLink,
  BCard, BRow, BCol, BFormInput, BTable, BMedia, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
  BTab, BTabs,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  ref, watch, onUnmounted,
} from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'
import VueApexCharts from 'vue-apexcharts'
import ApexCharts from 'apexcharts'
import store from '@/store'
import SalesListFilters from './SalesListFilters.vue'
// eslint-disable-next-line import/no-cycle
import useSalesList from './useSalesList'
// import sharedSalesList from './sharedSalesList'
import saleStoreModule from '../saleStoreModule'

export default {
  components: {
    SalesListFilters,
    BTab,
    BTabs,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    // BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    VueApexCharts,
  },
  data() {
    return {
    }
  },
  setup(_, { root }) {
    // console.log(props)
    const USER_APP_STORE_MODULE_NAME = 'app-sale'
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, saleStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })
    const isAddNewSaleSidebarActive = ref(false)
    /*
    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Author', value: 'author' },
      { label: 'Editor', value: 'editor' },
      { label: 'Maintainer', value: 'maintainer' },
      { label: 'Subscriber', value: 'subscriber' },
    ]
    /*
    const companyOptions = [
      { label: 'Basic', value: 'basic' },
      { label: 'Company', value: 'company' },
      { label: 'Enterprise', value: 'enterprise' },
      { label: 'Team', value: 'team' },
    ]
    */
    const statusOptions = [
      // { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]
    const {
      fetchSales,
      fetchReportSales,
      fetchSalesFile,
      tableColumns,
      tableReportColumns,
      perPage,
      perReportPage,
      currentPage,
      currentReportPage,
      totalSales,
      totalSalesSum,
      totalReportSales,
      dataMeta,
      dataReportMeta,
      perPageOptions,
      perPageReportOptions,
      searchQuery,
      searchReportQuery,
      sortBy,
      sortReportBy,
      isSortDirDesc,
      isSortDirReportDesc,
      refSaleListTable,
      refSaleListReportTable,
      refetchData,
      // UI
      resolveSaleRoleVariant,
      resolveSaleRoleIcon,
      resolveSaleStatusVariant,
      resolveSaleCompanyText,
      resolveSaleMachineText,
      // Extra Filters
      machineFilter,
      companyFilter,
      objectFilter,
      statusFilter,
      machineOptions,
      companyOptions,
      objectOptions,
      deleteSale,
      series,
      chartOptions,
      chartData,
      rangePicker,
      isReportBusy,
    } = useSalesList()
    const visibleRows = ref([])
    const visibleReportRows = ref([])
    const totalColumn = key => visibleReportRows.value.reduce((accum, item) => {
      // console.log(accum, key, visibleReportRows.value, item)
      // eslint-disable-next-line
      if (!item[key]) item[key] = 0
      return accum + parseFloat(item[key])
    }, 0)
    const ApexChartsExec = () => {
      root.$nextTick(() => {
        // console.log('TAB CLICK')
        if (chartOptions.value) ApexCharts.exec('chartSales', 'updateOptions', chartOptions.value)
      })
    }
    watch(chartData, () => {
      if (chartOptions.value) {
        if (this?.$refs?.chart) {
          ApexCharts.exec('chartSales', 'updateOptions', chartOptions.value)
        }
      }
    })
    return {
      // Sidebar
      isAddNewSaleSidebarActive,
      fetchSales,
      fetchReportSales,
      fetchSalesFile,
      tableColumns,
      tableReportColumns,
      perPage,
      perReportPage,
      currentPage,
      currentReportPage,
      totalSales,
      totalSalesSum,
      totalReportSales,
      dataMeta,
      dataReportMeta,
      perPageOptions,
      perPageReportOptions,
      searchQuery,
      searchReportQuery,
      sortBy,
      sortReportBy,
      isSortDirDesc,
      isSortDirReportDesc,
      refSaleListTable,
      refSaleListReportTable,
      refetchData,
      // Filter
      avatarText,
      // UI
      resolveSaleRoleVariant,
      resolveSaleRoleIcon,
      resolveSaleStatusVariant,
      resolveSaleCompanyText,
      resolveSaleMachineText,
      machineOptions,
      companyOptions,
      objectOptions,
      statusOptions,
      // Extra Filters
      machineFilter,
      companyFilter,
      objectFilter,
      statusFilter,
      deleteSale,
      visibleRows,
      visibleReportRows,
      series,
      chartOptions,
      ApexChartsExec,
      rangePicker,
      totalColumn,
      isReportBusy,
    }
  },
  computed: {
    backendUrl() {
      return process.env.VUE_APP_BASE
    },
    totalPrice() {
      // console.log(this)
      // eslint-disable-next-line
      return this.totalSalesSum
    },
    totalQuantity() {
      // console.log(this)
      // eslint-disable-next-line
      return this.visibleRows.reduce((accum, item) => {
        // console.log(item)
        return accum + parseFloat(item.quantity)
      }, 0.00)
    },
    totalBeverages() {
      return this.tableReportColumns.reduce((prev, current) => {
        const getNumbers = (this.visibleReportRows ?? []).filter(item => current?.key in item)
        if (getNumbers.length) {
          prev += getNumbers.reduce((prevValue, currentValue) => {
            if (Number.isInteger(currentValue[current?.key])) {
              prevValue += currentValue[current?.key] || 0
            }
            return prevValue
          }, 0)
        }
        return prev
      }, 0)
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
