import { ref, watch, computed } from '@vue/composition-api'
import { title } from '@core/utils/filter'
import Vue from 'vue'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import sharedSalesList from './sharedSalesList'
import moment from 'moment'
import store from '@/store'
import router from '@/router'
import i18n from '@/libs/i18n'

export default function useSalesList() {
  // console.log(router.currentRoute.params)

  // Use toast
  const toast = useToast()

  const refSaleListTable = ref(null)
  const refSaleListReportTable = ref(null)

  const isReportBusy = ref(false)

  // Table Handlers
  const tableColumns = [
    {
      key: 'time_sale',
      label: i18n.t('drinks.time'),
      formatter: title,
      sortable: true,
    },
    { key: 'p_l_u', label: i18n.t('drinks.plu'), sortable: false },
    { key: 'beverage_name', label: i18n.t('drinks.beverage'), sortable: false },
    {
      key: 'price',
      label: i18n.t('drinks.price'),
      formatter: title,
      sortable: false,
    },
    { key: 'quantity', label: i18n.t('drinks.quantity'), sortable: true },
    { key: 'details', label: i18n.t('drinks.details'), sortable: false },
    { key: 'type', label: i18n.t('drinks.type'), sortable: true },
    /*
    {
      key: 'sale',
      label: 'Sale',
      formatter: title,
      sortable: true,
    },
    {
      key: 'description',
      label: 'Description',
      formatter: title,
      sortable: true,
    },
    */
    {
      key: 'machine_name',
      label: i18n.t('drinks.machine'),
      formatter: title,
      sortable: true,
    },
    {
      key: 'withCheck',
      label: i18n.t('common.print'),
    },
  ]

  const tableColumnsTemplate = [
    {
      key: 'machine_name',
      label: i18n.t('components.machine'),
      formatter: title,
      sortable: true,
    },
  ]

  const tableReportColumns = ref([...tableColumnsTemplate])
  const perPage = ref(10)
  const perReportPage = ref(10)

  const totalSales = ref(0)
  const totalSalesSum = ref(0)
  const totalReportSales = ref(0)
  const currentPage = ref(1)
  const currentReportPage = ref(1)
  const perPageOptions = [10, 50, 250, 500]
  const perPageReportOptions = [10, 50, 250, 500]
  const searchQuery = ref('')
  const searchReportQuery = ref('')
  const sortBy = ref('id')
  const sortReportBy = ref('id')
  const isSortDirDesc = ref(true)
  const isSortDirReportDesc = ref(true)
  const rangePicker = ref([moment().set({
    hour: 0, minute: 0, second: 0, millisecond: 0,
  }).format('YYYY-MM-DD HH:mm')
    .toString(), moment().set({
    hour: 23, minute: 59, second: 0, millisecond: 0,
  }).format('YYYY-MM-DD HH:mm')
    .toString()])
  // const rangePicker = ref(null)

  if (router.currentRoute.params.machineFilter) {
    store.commit('appConfig/UPDATE_MACHINE_FILTER', router.currentRoute.params.machineFilter)
    store.commit('appConfig/UPDATE_OBJECT_FILTER', null)
    store.commit('appConfig/UPDATE_COMPANY_FILTER', null)
  }

  const companyFilter = ref(store.state.appConfig.filters.company ? store.state.appConfig.filters.company : null)
  const objectFilter = ref(store.state.appConfig.filters.object ? store.state.appConfig.filters.object : [])
  const machineFilter = ref(store.state.appConfig.filters.machine ? store.state.appConfig.filters.machine : null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refSaleListTable.value ? refSaleListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalSales.value,
    }
  })

  const dataReportMeta = computed(() => {
    const localItemsCount = refSaleListReportTable.value ? refSaleListReportTable.value.localItems.length : 0
    return {
      from: perReportPage.value * (currentReportPage.value - 1) + (localItemsCount ? 1 : 0),
      to: totalReportSales.value > (perReportPage.value * (currentReportPage.value - 1) + localItemsCount) ? perReportPage.value * (currentReportPage.value - 1) + localItemsCount : totalReportSales.value,
      of: totalReportSales.value,
    }
  })

  const chartData = ref(new Date())

  const series = ref([{
    data: [],
  }])

  // eslint-disable-next-line
  const chartOptions = ref({
    chart: {
      type: 'bar',
      height: 0,
      id: 'chartSales',
      toolbar: {
        // show: false,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 15,
        endingShape: 'rounded',
        barHeight: '100%',
        distributed: true,
        horizontal: true,
        dataLabels: {
          position: 'bottom',
        },
      },
    },
    // colors: ['#33b2df', '#546E7A', '#d4526e', '#13d8aa', '#A5978B', '#2b908f', '#f9a3a4', '#90ee7e',
    //   '#f48024', '#69d2e7',
    // ],
    dataLabels: {
      enabled: true,
      textAnchor: 'start',
      style: {
        colors: ['#000000'],
      },
      formatter(val, opt) {
        return `${opt.w.globals.labels[opt.dataPointIndex]}:  ${val}`
      },
      offsetX: 0,
      dropShadow: {
        enabled: true,
        color: '#aaaaaa',
        blur: 5,
        opacity: 0.7,
      },
    },
    stroke: {
      width: 1,
      colors: ['#fff'],
    },
    xaxis: {
      categories: [],
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    /*
    title: {
      text: 'Custom DataLabels',
      align: 'center',
      floating: true,
    },
    subtitle: {
      text: 'Category Names as DataLabels inside bars',
      align: 'center',
    },
     */
    tooltip: {
      show: false,
      theme: 'dark',
      x: {
        show: false,
      },
      y: {
        show: false,
        title: {
          formatter() {
            return ''
          },
        },
      },
    },
  })

  const fetchReportSales = ref([])

  const fetchSales = (ctx, callback) => {
    isReportBusy.value = true
    store
      .dispatch('app-sale/fetchSales', {
        q: searchQuery.value,
        perPage: perPage.value,
        perReportPage: perReportPage.value,
        page: currentPage.value,
        pageReport: currentReportPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        machine: machineFilter.value,
        object: objectFilter.value,
        company: companyFilter.value,
        status: statusFilter.value,
        dateRange: rangePicker.value, // .join(' '),
      })
      .then(response => {
        isReportBusy.value = false
        const {
          xaxis, seriesdata, sales, total, columns, report, totalReport, salesSum,
        } = response.data

        // console.log('Before')
        // console.log('Before', chartOptions.value.chart.height)
        chartOptions.value.xaxis.categories = xaxis
        chartOptions.value.chart.height = 85 + 30 * xaxis.length
        // console.log('After', chartOptions.value.chart.height)
        series.value = [{ data: seriesdata }]
        chartData.value = new Date()

        fetchReportSales.value = report

        tableReportColumns.value = [
          ...tableColumnsTemplate,
          ...columns,
        ]
        callback(sales)
        totalSalesSum.value = salesSum
        totalSales.value = total
        totalReportSales.value = totalReport
      })
      // .catch(err => {
      .catch(() => {
        isReportBusy.value = false
        // console.log(err)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching sales list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const refetchData = () => {
    refSaleListTable.value.refresh()
  }

  watch([currentPage, perPage, currentReportPage, perReportPage, searchQuery, machineFilter, companyFilter, objectFilter, statusFilter, rangePicker], () => {
    if (!companyFilter.value) {
      localStorage.removeItem('companyId')
    }
    // console.log('+++++++++++')
    refetchData()
  })

  const fetchSalesFile = (type = 'xlsx', section = '') => {
    store
      .dispatch('app-sale/fetchSales', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        machine: machineFilter.value,
        object: objectFilter.value,
        company: companyFilter.value,
        status: statusFilter.value,
        dateRange: rangePicker.value,
        type,
        section,
      })
      .then(response => {
        const { data } = response
        const buf = Buffer.from(data.data)
        const contentType = type === 'xlsx' ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' : 'application/pdf'
        const blob = new Blob([buf], { type: contentType })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = `Sales.${type}`
        link.click()
        URL.revokeObjectURL(link.href)
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching export file',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const deleteSale = item => {
    // console.log(Vue.swal)
    // console.log(store._vm)
    Vue.swal({
      title: 'Are you sure?',
      html: `Sale <b>${item.name}</b> will be deleted. \nYou won't be able to revert this!`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-danger ml-1',
      },
      buttonsStyling: false,
    }).then(result => {
      if (result.value) {
        store
          .dispatch('app-sale/deleteSale', { id: item.id })
          .then(() => {
            refetchData()
            Vue.swal({
              icon: 'success',
              title: 'Deleted!',
              text: `Sale ${item.name} has been deleted.`,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          })
          .catch(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Sale delete error',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    })
  }

  const machineOptions = ref([])
  store.dispatch('app-sale/fetchMachines')
    .then(response => {
      // console.log('RESP', response.data)
      machineOptions.value = response.data.data
      // console.log('roleOptions', companyOptions)
    })
    .catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching machines list',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

  const companyOptions = ref([])
  store.dispatch('app-sale/fetchCompanies')
    .then(response => {
      // console.log('RESP', response.data)
      companyOptions.value = response.data.data
      // console.log('companyOptions', companyOptions)
    })
    .catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching companies list',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

  const objectOptions = ref([])
  store.dispatch('app-sale/fetchObjects')
    .then(response => {
      // console.log('RESP', response.data)
      objectOptions.value = response.data.data
      // console.log('roleOptions', companyOptions)
    })
    .catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching roles list',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveSaleStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  const resolveSaleCompanyText = company => {
    if (!company) {
      return ''
    }
    try {
      return companyOptions.value.find(o => o.value === company).label
    } catch (e) {
      return 'undefined'
    }
  }

  const resolveSaleMachineText = machine => {
    if (!machine) {
      return ''
    }
    try {
      return machineOptions.value.find(o => o.value === machine).label
    } catch (e) {
      return 'undefined'
    }
  }

  return {

    fetchSales,
    fetchReportSales,
    fetchSalesFile,
    tableColumns,
    tableReportColumns,
    perPage,
    perReportPage,
    currentPage,
    currentReportPage,

    totalSales,
    totalSalesSum,
    totalReportSales,
    dataMeta,
    dataReportMeta,
    perPageOptions,
    perPageReportOptions,
    searchQuery,
    searchReportQuery,
    sortBy,
    sortReportBy,
    isSortDirDesc,
    isSortDirReportDesc,
    refSaleListTable,
    refSaleListReportTable,

    resolveSaleStatusVariant,
    resolveSaleCompanyText,
    resolveSaleMachineText,
    refetchData,

    // Extra Filters
    machineFilter,
    companyFilter,
    objectFilter,
    statusFilter,

    companyOptions,
    objectOptions,
    machineOptions,

    deleteSale,

    series,
    chartOptions,
    chartData,

    rangePicker,
    isReportBusy,

  }
}
